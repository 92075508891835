@import './colors.scss';

:root {
  --toastify-toast-width: 330px;
}

* {
  box-sizing: border-box;
  font-size: inherit;
  font-family: Manrope;
}

body {
  background-color: $background-navy;
  color: $text-white;
}

a {
  text-decoration: none;
}

.Toastify__toast-container--top-right {
  top: 6em;
}

.Toastify__toast {
  border-radius: 20px;
  font-size: 19px;
  font-weight: 500;
  padding: 17px 20px;
  position: relative;
  overflow: visible;
  color: white !important;

  p {
    margin: 0;
  }

  &.Toastify__toast--success {
    background: #22c55e;
  }

  &.Toastify__toast--error {
    background: #ef4444;
    color: #fff !important;
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;

    .Toastify__toast-icon {
      width: 44px;
      height: 44px;
    }

    .Toastify__toast-submsg {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .Toastify__close-button {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
    background: #fff;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    opacity: 1;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
