

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Variable.ttf") format("truetype-variations");
  font-weight: 200 800;
}

@font-face {
  font-family: "Monoid";
  src: url("../fonts/Monoid-Regular.ttf") format("truetype");
  font-weight: 400;
}